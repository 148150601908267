import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { Redirect } from 'react-router';
import { Capacitor } from '@capacitor/core';
import {
  IonButton,
  IonContent,
  IonIcon,
  IonPage,
  IonRouterLink,
  IonRow,
  IonSpinner,
  IonText
} from '@ionic/react';
import firebase from 'firebase/compat/app';
import { getApp } from 'firebase/app';
import 'firebase/compat/auth';
import {
  getAuth,
  GoogleAuthProvider,
  indexedDBLocalPersistence,
  initializeAuth,
  OAuthProvider,
  signInWithCredential,
  signInWithEmailAndPassword
} from 'firebase/auth';
import {
  FirebaseAuthentication,
  GetCurrentUserResult
} from '@capacitor-firebase/authentication';
import {
  requestTokenForFirebaseUserSuccess,
  setAuthEmail,
  setAuthIdToken,
  setError,
  setLoginMethod
} from 'features/shared/login/LoginSlice';
import {
  capitalize,
  gaBtnClickEvent,
  loadAuthTokenFromStorage
} from 'core/util';
import {
  requestTokenForFirebaseUser,
  resetAppToInitialState
} from './LoginActions';
import Illustration from 'components/Illustrations/Illustration';
import HomeKeepLogo from 'assets/illustrations/hk-logo-dark.svg';
import AppleLogo from 'assets/illustrations/apple-logo.svg';
import GoogleLogo from 'assets/illustrations/google-logo.svg';
import { useForm } from 'react-hook-form';
import { firebaseConfig, HKPlatform } from 'core/constants';
import './Login.scss';

enum LoginMethod {
  GOOGLE = 'google',
  APPLE = 'apple',
  EMAIL = 'email'
}

const Login: React.FC = () => {
  const { authEmail, authIdToken, accessToken, loading, loginMethod, error } =
    useSelector((state: RootState) => state.login);

  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { register, handleSubmit } = useForm<FormData>();
  const dispatch = useDispatch();
  firebase.initializeApp(firebaseConfig);

  type FormData = {
    email: string;
    password: string;
  };

  const getCurrentUser = async (): Promise<GetCurrentUserResult> => {
    const result = await FirebaseAuthentication.getCurrentUser();
    return result;
  };

  const getIdToken = async () => {
    const result = await FirebaseAuthentication.getIdToken();
    return result.token;
  };

  const onFormSubmit = handleSubmit(({ email, password }) => {
    signInWithPassword(email, password).then();
  });

  const signInWithPassword = async (email: string, password: string) => {
    gaBtnClickEvent('login_with_password');
    dispatch(setLoginMethod(LoginMethod.EMAIL));
    dispatch(setError(null));
    const result = await FirebaseAuthentication.signInWithEmailAndPassword({
      email: email,
      password: password
    }).catch((error) => {
      dispatch(setError('Sign in failed. Please try again.'));
    });
    if (!!result) {
      let auth;
      if (Capacitor.isNativePlatform()) {
        auth = initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence
        });
      } else {
        auth = getAuth();
      }
      getCurrentUser().then((result) => {
        if (!!result.user) {
          getIdTokenForEmail(result.user.email!);
        }
      });
      await signInWithEmailAndPassword(auth, email, password);
    }
  };

  const handleError = (err: any) => {
    console.log(err);
  };

  const signInWithApple = async () => {
    gaBtnClickEvent('login_with_apple');
    dispatch(setLoginMethod(LoginMethod.APPLE));
    dispatch(setError(null));
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithApple().catch(
      handleError
    );
    if (!!result) {
      // 2. Sign in on the web layer using the id token and nonce
      const provider = new OAuthProvider('apple.com');
      const credential = provider.credential({
        idToken: result.credential?.idToken,
        rawNonce: result.credential?.nonce
      });
      let auth;
      if (Capacitor.isNativePlatform()) {
        auth = initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence
        });
      } else {
        auth = getAuth();
      }
      getCurrentUser().then((result) => {
        if (!!result.user) {
          getIdTokenForEmail(result.user.email!);
        }
      });
      await signInWithCredential(auth, credential);
    }
  };

  const signInWithGoogle = async () => {
    gaBtnClickEvent('login_with_google');
    dispatch(setLoginMethod(LoginMethod.GOOGLE));
    dispatch(setError(null));
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithGoogle().catch(
      handleError
    );
    if (!!result) {
      // 2. Sign in on the web layer using the id token
      const credential = GoogleAuthProvider.credential(
        result.credential?.idToken
      );
      let auth;
      if (Capacitor.isNativePlatform()) {
        auth = initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence
        });
      } else {
        auth = getAuth();
      }
      getCurrentUser().then((result) => {
        if (!!result.user) {
          getIdTokenForEmail(result.user.email!);
        }
      });
      await signInWithCredential(auth, credential);
    }
  };

  const getIdTokenForEmail = (email: string) => {
    dispatch(resetAppToInitialState());
    dispatch(setAuthEmail(email));
    getIdToken().then((token) => {
      dispatch(setAuthIdToken(token));
    });
  };

  const openSignup = () => {
    gaBtnClickEvent('sign_up');
    window.open('/signup/plans', '_self');
  };

  useEffect(() => {
    if (!!authEmail && !!authIdToken) {
      dispatch(requestTokenForFirebaseUser(authIdToken));
    }
  }, [dispatch, authEmail, authIdToken]);

  useEffect(() => {
    if (!accessToken) {
      loadAuthTokenFromStorage().then((token) => {
        if (token) {
          dispatch(requestTokenForFirebaseUserSuccess({ access_token: token }));
        }
      });
    }
  }, [dispatch, accessToken]);

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onFormSubmit();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => {
    dispatch(setError(null));
  }, []);

  function iOSButtons(buttonSize: 'small' | 'default' | 'large' | undefined) {
    return (
      <div>
        <IonButton
          className="hk-auth-button ion-text-left ion-margin-vertical"
          color="white"
          size={buttonSize}
          strong={true}
          onClick={signInWithApple}
        >
          <IonIcon
            src={AppleLogo}
            slot="start"
            className="ion-margin-horizontal"
          />
          Sign In with Apple
        </IonButton>
        <IonButton
          className="hk-auth-button ion-text-left"
          color="white"
          size={buttonSize}
          strong={true}
          onClick={signInWithGoogle}
        >
          <IonIcon
            src={GoogleLogo}
            slot="start"
            className="ion-margin-horizontal"
          />
          Sign In with Google
        </IonButton>
      </div>
    );
  }

  function androidButtons(
    buttonSize: 'small' | 'default' | 'large' | undefined
  ) {
    return (
      <div>
        <IonButton
          className="hk-auth-button ion-text-left ion-margin-vertical"
          color="white"
          size={buttonSize}
          strong={true}
          onClick={signInWithGoogle}
        >
          <IonIcon
            src={GoogleLogo}
            slot="start"
            className="ion-margin-horizontal"
          />
          Sign In with Google
        </IonButton>
        <IonButton
          className="hk-auth-button ion-text-left"
          color="white"
          size={buttonSize}
          strong={true}
          onClick={signInWithApple}
        >
          <IonIcon
            src={AppleLogo}
            slot="start"
            className="ion-margin-horizontal"
          />
          Sign In with Apple
        </IonButton>
      </div>
    );
  }

  function DesktopLoginView() {
    return (
      <IonPage>
        <IonContent className="login-view-desktop">
          <div className="login-view-desktop-container">
            <Illustration image={HomeKeepLogo} description="HomeKeep" />
            <div className="login-content-container">
              <div className="login-content">
                <h3 className="login-title ion-text-center">
                  <b>Stress-Free Home Upkeep</b>
                </h3>
                <h6 className="login-subheader ion-padding-horizontal ion-text-center">
                  Preventative care and regular maintenance for every inch of
                  your home.
                </h6>
                <section className="login-view-provider-list">
                  <section className="login-view-password-form">
                    <form className="hk-form" onSubmit={onFormSubmit}>
                      <div className="hk-form-row">
                        <input
                          type="text"
                          placeholder="Email"
                          name="email"
                          className="login-field"
                          autoCorrect="off"
                          autoCapitalize="none"
                          color="medium"
                          ref={register({
                            required: 'You must specify an email.'
                          })}
                        />
                      </div>
                      <div className="hk-form-row">
                        <input
                          type="password"
                          name="password"
                          placeholder="Password"
                          className="login-field"
                          autoCorrect="off"
                          autoCapitalize="none"
                          color="medium"
                          ref={register({
                            required: 'You must specify a password.'
                          })}
                        />
                      </div>
                      <div className="hk-form-actions">
                        <div className="hk-form-actions-forgot-password ion-text-right">
                          <IonRouterLink
                            className="hk-form-actions-forgot-password-link"
                            routerLink="forgot-password"
                          >
                            Forgot Password?
                          </IonRouterLink>
                        </div>

                        <IonButton
                          className="hk-auth-button"
                          expand="block"
                          color="primary"
                          type="submit"
                          strong={true}
                          disabled={loading}
                        >
                          {loading ? <IonSpinner name="dots" /> : 'Sign In'}
                        </IonButton>
                      </div>
                    </form>
                  </section>
                  <div className="login-view-provider-list-separator">
                    <span>or</span>
                    <hr />
                  </div>
                  {platformType !== HKPlatform.ANDROID && iOSButtons('default')}
                  {platformType === HKPlatform.ANDROID &&
                    androidButtons('default')}
                </section>
              </div>
            </div>
            {loading && (
              <div className="hk-body-spinner">
                <IonRow className="vertical-align-center">
                  <IonSpinner name="dots" />
                </IonRow>
              </div>
            )}
            {error && (
              <div className="login-view-error">
                {!loginMethod ? (
                  <>
                    <p>Sign In failed.</p>
                  </>
                ) : (
                  <>
                    <p>{`Sign In with ${capitalize(loginMethod)} failed.`}</p>
                    {(loginMethod === LoginMethod.APPLE ||
                      loginMethod === LoginMethod.GOOGLE) && (
                      <p>Please Sign Up to continue.</p>
                    )}
                  </>
                )}
              </div>
            )}
            <div className="login-view-footer ion-margin-vertical">
              <IonText color="medium">Don't have an Account?</IonText>
              <a
                className="login-view-footer-link"
                color="primary"
                onClick={() => openSignup()}
              >
                {' '}
                Sign up
              </a>
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  }

  function MobileLoginView() {
    return (
      <IonPage>
        <IonContent
          className="login-view ion-padding"
          fullscreen
          scrollY={true}
        >
          <Illustration image={HomeKeepLogo} description="HomeKeep" />
          <div>
            <h3 className="ion-text-center">
              <b>Stress-Free Home Upkeep</b>
            </h3>
          </div>
          <section className="login-view-provider-list">
            <section className="login-view-password-form">
              <form className="hk-form" onSubmit={onFormSubmit}>
                <div className="hk-form-row">
                  <input
                    type="text"
                    placeholder="Email"
                    name="email"
                    className="login-field"
                    autoCorrect="off"
                    autoCapitalize="none"
                    color="medium"
                    ref={register({
                      required: 'You must specify an email.'
                    })}
                  />
                </div>
                <div className="hk-form-row">
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    className="login-field"
                    autoCorrect="off"
                    autoCapitalize="none"
                    color="medium"
                    ref={register({
                      required: 'You must specify a password.'
                    })}
                  />
                </div>
                <div className="hk-form-actions">
                  <div className="hk-form-actions-forgot-password ion-text-right">
                    <IonRouterLink
                      className="hk-form-actions-forgot-password-link"
                      routerLink="forgot-password"
                    >
                      Forgot Password?
                    </IonRouterLink>
                  </div>
                  <IonButton
                    className="hk-auth-button"
                    expand="block"
                    color="primary"
                    type="submit"
                    strong={true}
                    disabled={loading}
                  >
                    {loading ? <IonSpinner name="dots" /> : 'Sign In'}
                  </IonButton>
                </div>
              </form>
            </section>
            <div className="login-view-provider-list-separator">
              <span>or</span>
              <hr />
            </div>
            {platformType !== HKPlatform.ANDROID && iOSButtons('large')}
            {platformType === HKPlatform.ANDROID && androidButtons('large')}
          </section>
          {loading && (
            <div className="hk-body-spinner">
              <IonRow className="vertical-align-center">
                <IonSpinner name="dots" />
              </IonRow>
            </div>
          )}
          {error && (
            <div className="login-view-error">
              {!loginMethod ? (
                <>
                  <p>Sign In failed.</p>
                </>
              ) : (
                <>
                  <p>{`Sign In with ${capitalize(loginMethod)} failed.`}</p>
                  {(loginMethod === LoginMethod.APPLE ||
                    loginMethod === LoginMethod.GOOGLE) && (
                    <p>Please Sign Up to continue.</p>
                  )}
                </>
              )}
            </div>
          )}
          <div className="login-view-footer">
            <IonText color="medium">Don't have an Account?</IonText>
            <a
              className="login-view-footer-link"
              color="primary"
              onClick={() => openSignup()}
            >
              {' '}
              Sign up
            </a>
          </div>
        </IonContent>
      </IonPage>
    );
  }

  return accessToken ? (
    <Redirect to="/checkpoint" />
  ) : (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopLoginView()
        : MobileLoginView()}
    </>
  );
};

export default Login;
