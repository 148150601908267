import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { Redirect } from 'react-router';
import {
  IonButton,
  IonContent,
  IonIcon,
  IonPage,
  IonRow,
  IonSpinner
} from '@ionic/react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {
  getAuth,
  confirmPasswordReset,
  verifyPasswordResetCode,
  initializeAuth,
  indexedDBLocalPersistence,
  signInWithEmailAndPassword
} from 'firebase/auth';
import {
  FirebaseAuthentication,
  GetCurrentUserResult
} from '@capacitor-firebase/authentication';
import { useForm } from 'react-hook-form';
import { firebaseConfig, HKPlatform, PASSWORD_REGEX } from 'core/constants';
import HomeKeepLogo from 'assets/illustrations/hk-logo-dark.svg';
import './PasswordReset.scss';
import Illustration from 'components/Illustrations/Illustration';
import {
  requestTokenForFirebaseUserSuccess,
  setAuthEmail,
  setAuthIdToken,
  setError
} from './LoginSlice';
import { requestTokenForFirebaseUser } from './LoginActions';
import { loadAuthTokenFromStorage } from 'core/util';
import { setLoading } from 'core/services/LoadingSlice';
import LoadingSpinner, { LoadingMode } from 'components/loading/LoadingSpinner';
import { Capacitor } from '@capacitor/core';
import { getApp } from 'firebase/app';
import Header from '../signup/views/Header';
import Footer from '../signup/views/Footer';
import CompleteIcon from 'assets/icons/complete.svg';

const PasswordReset: React.FC = () => {
  const { loading, authEmail, authIdToken, accessToken } = useSelector(
    (state: RootState) => state.login
  );
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const [pageLoading, setPageLoading] = useState(true);
  const [showPasswordResetForm, setShowPasswordResetForm] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const { register, handleSubmit, formState, getValues, trigger } =
    useForm<FormData>({
      defaultValues: { password: '', passwordConfirm: '' },
      reValidateMode: 'onChange',
      criteriaMode: 'all',
      mode: 'onChange'
    });
  const { isValid, errors } = formState;
  const dispatch = useDispatch();

  type FormData = {
    password: string;
    passwordConfirm: string;
  };

  // Configure Firebase.
  firebase.initializeApp(firebaseConfig);

  const getCurrentUser = async (): Promise<GetCurrentUserResult> => {
    const result = await FirebaseAuthentication.getCurrentUser();
    return result;
  };

  const getIdToken = async () => {
    const result = await FirebaseAuthentication.getIdToken();
    return result.token;
  };

  const getIdTokenForEmail = (email: string) => {
    dispatch(setAuthEmail(email));
    getIdToken().then((token) => {
      dispatch(setAuthIdToken(token));
    });
  };

  const signInWithPassword = async (email: string, password: string) => {
    dispatch(setError(null));
    const result = await FirebaseAuthentication.signInWithEmailAndPassword({
      email: email,
      password: password
    }).catch((error) => {
      dispatch(setError('Sign in failed. Please try again.'));
    });
    if (!!result) {
      let auth;
      if (Capacitor.isNativePlatform()) {
        auth = initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence
        });
      } else {
        auth = getAuth();
      }
      getCurrentUser().then((result) => {
        if (!!result.user) {
          getIdTokenForEmail(result.user.email!);
        }
      });
      await signInWithEmailAndPassword(auth, email, password);
    }
  };

  const verifyFirebaseActionCode = () => {
    const auth = getAuth();
    const params = new URLSearchParams(window.location.search);
    let actionCode = params.get('oobCode')!;
    // Verify the password reset code is valid
    verifyPasswordResetCode(auth, actionCode)
      .then((email) => {
        setUserEmail(email);
        setShowPasswordResetForm(true);
        setPageLoading(false);
      })
      .catch((error) => {
        // Invalid or expired action code. Ask user to try to reset the password again.
        setPageLoading(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(`${errorCode}: ${errorMessage}`);
      });
  };

  const handlePasswordReset = (password: string) => {
    const auth = getAuth();
    const params = new URLSearchParams(window.location.search);
    let actionCode = params.get('oobCode')!;
    confirmPasswordReset(auth, actionCode, password)
      .then((response) => {
        signInWithPassword(userEmail, password).then();
      })
      .catch((error) => {
        dispatch(setLoading(false));
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(`${errorCode}: ${errorMessage}`);
      });
  };

  const onSubmit = handleSubmit(({ password, passwordConfirm }) => {
    if (errors.password === undefined && errors.passwordConfirm === undefined) {
      dispatch(setLoading(true));
      handlePasswordReset(password);
    }
  });

  useEffect(() => {
    if (!!authEmail && !!authIdToken) {
      dispatch(requestTokenForFirebaseUser(authIdToken));
    }
  }, [dispatch, authEmail, authIdToken]);

  useEffect(() => {
    if (!accessToken) {
      loadAuthTokenFromStorage().then((token) => {
        if (token) {
          dispatch(requestTokenForFirebaseUserSuccess({ access_token: token }));
        }
      });
    }
  }, [dispatch, accessToken]);

  useEffect(() => {
    verifyFirebaseActionCode();
  }, []);

  function DesktopPasswordResetView() {
    return (
      <IonPage>
        <Header />
        <IonContent className="hk-desktop-password-reset-view">
          {showPasswordResetForm && (
            <>
              <div className="hk-desktop-password-reset-view-container">
                <section className="hk-desktop-password-reset-view-header">
                  <h3 className="hk-desktop-password-reset-view-title ion-text-center">
                    <b>Reset Your Password</b>
                  </h3>
                  <h6 className="hk-desktop-password-reset-view-subheader ion-padding-horizontal ion-text-center">
                    for <b>{userEmail}</b>
                  </h6>
                </section>
                <div className="hk-desktop-password-reset-view-content">
                  <section className="hk-desktop-password-reset-view-reset-form">
                    <form
                      className="hk-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div className="hk-desktop-password-reset-view-content-container">
                        <div className="hk-form-row">
                          <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            onChange={() => {
                              trigger('passwordConfirm');
                            }}
                            ref={register({
                              validate: {
                                hasSpecialCharacter: (value) => {
                                  const validation = /(?=.*[\d@$!%*?&])/.test(
                                    value
                                  );
                                  return validation;
                                },
                                hasMinimumLength: (value) => {
                                  const validation = value.length >= 8;
                                  return validation;
                                },
                                hasUpperCase: (value) => {
                                  const validation = /.*[A-Z]+.*/.test(value);
                                  return validation;
                                }
                              }
                            })}
                          />
                        </div>
                        <div className="hk-form-row">
                          <input
                            type="password"
                            name="passwordConfirm"
                            placeholder="Confirm password"
                            ref={register({
                              required: 'Please confirm your password',
                              validate: (value) =>
                                value === getValues('password') ||
                                'The passwords do not match'
                            })}
                          />
                        </div>

                        <section className="hk-form-validation-container">
                          <div
                            className={`hk-form-validation ${
                              !formState.isDirty ||
                              (errors.password &&
                                errors.password?.types?.hasOwnProperty(
                                  'hasMinimumLength'
                                ))
                                ? 'invalid'
                                : ''
                            }`}
                          >
                            <IonIcon icon={CompleteIcon} />
                            At least 8 characters
                          </div>
                          <div
                            className={`hk-form-validation ${
                              !formState.isDirty ||
                              (errors.password &&
                                errors.password?.types?.hasOwnProperty(
                                  'hasUpperCase'
                                ))
                                ? 'invalid'
                                : ''
                            }`}
                          >
                            <IonIcon icon={CompleteIcon} />
                            At least 1 uppercase letter
                          </div>
                          <div
                            className={`hk-form-validation ${
                              !formState.isDirty ||
                              (errors.password &&
                                errors.password?.types?.hasOwnProperty(
                                  'hasSpecialCharacter'
                                ))
                                ? 'invalid'
                                : ''
                            }`}
                          >
                            <IonIcon icon={CompleteIcon} />
                            At least 1 number or special character
                          </div>
                        </section>
                        {errors.passwordConfirm && (
                          <p className="hk-error">
                            {errors.passwordConfirm.message}
                          </p>
                        )}
                      </div>
                      <div className="hk-form-actions">
                        <IonButton
                          className="hk-auth-button ion-margin-vertical"
                          color="primary"
                          size="default"
                          strong={true}
                          disabled={!isValid}
                          onClick={onSubmit}
                        >
                          Set Password
                        </IonButton>
                      </div>
                    </form>
                  </section>
                </div>
              </div>
            </>
          )}
          {!showPasswordResetForm && (
            <section className="hk-desktop-password-reset-view-header">
              <h3 className="hk-desktop-password-reset-view-title ion-text-center">
                <b>Try resetting your password again</b>
              </h3>
              <h6 className="hk-desktop-password-reset-view-subheader ion-padding-horizontal ion-text-center">
                Your request to reset your password has expired or the link has
                already been used.
              </h6>
            </section>
          )}

          {loading && (
            <div className="hk-body-spinner">
              <IonRow className="vertical-align-center">
                <IonSpinner name="dots" />
              </IonRow>
            </div>
          )}
        </IonContent>
        <Footer darkMode />
      </IonPage>
    );
  }

  function MobilePasswordResetView() {
    return (
      <IonPage>
        <Header />
        <IonContent className="hk-password-reset-view">
          {showPasswordResetForm && (
            <>
              {' '}
              <div className="hk-password-reset-view-container">
                <section className="hk-password-reset-view-header">
                  <h3 className="hk-password-reset-view-title ion-text-center">
                    <b>Reset Your Password</b>
                  </h3>
                  <h6 className="hk-password-reset-view-subheader ion-padding-horizontal ion-text-center">
                    for <b>{userEmail}</b>
                  </h6>
                </section>
                <div className="hk-password-reset-view-content">
                  <section className="hk-password-reset-view-reset-form">
                    <form
                      className="hk-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div className="hk-password-reset-view-content-container">
                        <div className="hk-form-row">
                          <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            onChange={() => {
                              trigger('passwordConfirm');
                            }}
                            ref={register({
                              validate: {
                                hasSpecialCharacter: (value) => {
                                  const validation = /(?=.*[\d@$!%*?&])/.test(
                                    value
                                  );
                                  return validation;
                                },
                                hasMinimumLength: (value) => {
                                  const validation = value.length >= 8;
                                  return validation;
                                },
                                hasUpperCase: (value) => {
                                  const validation = /.*[A-Z]+.*/.test(value);
                                  return validation;
                                }
                              }
                            })}
                          />
                        </div>
                        <div className="hk-form-row">
                          <input
                            type="password"
                            name="passwordConfirm"
                            placeholder="Confirm password"
                            ref={register({
                              required: 'Please confirm your password',
                              validate: (value) =>
                                value === getValues('password') ||
                                'The passwords do not match'
                            })}
                          />
                        </div>

                        <section className="hk-form-validation-container">
                          <div
                            className={`hk-form-validation ${
                              !formState.isDirty ||
                              (errors.password &&
                                errors.password?.types?.hasOwnProperty(
                                  'hasMinimumLength'
                                ))
                                ? 'invalid'
                                : ''
                            }`}
                          >
                            <IonIcon icon={CompleteIcon} />
                            At least 8 characters
                          </div>
                          <div
                            className={`hk-form-validation ${
                              !formState.isDirty ||
                              (errors.password &&
                                errors.password?.types?.hasOwnProperty(
                                  'hasUpperCase'
                                ))
                                ? 'invalid'
                                : ''
                            }`}
                          >
                            <IonIcon icon={CompleteIcon} />
                            At least 1 uppercase letter
                          </div>
                          <div
                            className={`hk-form-validation ${
                              !formState.isDirty ||
                              (errors.password &&
                                errors.password?.types?.hasOwnProperty(
                                  'hasSpecialCharacter'
                                ))
                                ? 'invalid'
                                : ''
                            }`}
                          >
                            <IonIcon icon={CompleteIcon} />
                            At least 1 number or special character
                          </div>
                        </section>
                        {errors.passwordConfirm && (
                          <p className="hk-error">
                            {errors.passwordConfirm.message}
                          </p>
                        )}
                      </div>
                      <div className="hk-form-actions">
                        <IonButton
                          className="hk-auth-button ion-margin-vertical"
                          color="primary"
                          size="default"
                          strong={true}
                          disabled={!isValid}
                          onClick={onSubmit}
                        >
                          Set Password
                        </IonButton>
                      </div>
                    </form>
                  </section>
                </div>
              </div>
            </>
          )}

          {!showPasswordResetForm && (
            <section className="hk-password-reset-view-header">
              <h3 className="hk-password-reset-view-title ion-text-center">
                <b>Try resetting your password again</b>
              </h3>
              <h6 className="hk-password-reset-view-subheader ion-padding-horizontal ion-text-center">
                Your request to reset your password has expired or the link has
                already been used.
              </h6>
            </section>
          )}

          {loading && (
            <div className="hk-body-spinner">
              <IonRow className="vertical-align-center">
                <IonSpinner name="dots" />
              </IonRow>
            </div>
          )}
        </IonContent>
        <Footer darkMode />
      </IonPage>
    );
  }

  return accessToken ? (
    <Redirect to="/checkpoint" />
  ) : (
    <>
      {pageLoading ? (
        <LoadingSpinner mode={LoadingMode.Logo} />
      ) : (
        <>
          {platformType === HKPlatform.DESKTOP && isDesktopWidth
            ? DesktopPasswordResetView()
            : MobilePasswordResetView()}
        </>
      )}
    </>
  );
};

export default PasswordReset;
