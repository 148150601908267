import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { Redirect, useHistory } from 'react-router';
import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonPage,
  IonRouterLink,
  IonRow,
  IonSpinner
} from '@ionic/react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { useForm } from 'react-hook-form';
import { firebaseConfig, HKPlatform } from 'core/constants';
import LeftArrow from 'assets/icons/arrow-left.svg';
import HomeKeepLogo from 'assets/illustrations/hk-logo-dark.svg';
import { setLoading } from 'core/services/LoadingSlice';
import './ForgotPasswordReset.scss';
import Illustration from 'components/Illustrations/Illustration';
import { gaBtnClickEvent } from 'core/util';

const ForgotPasswordReset: React.FC = () => {
  const { accessToken, loading } = useSelector(
    (state: RootState) => state.login
  );
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const [passwordEmailSent, setPasswordEmailSent] = useState(false);
  const [paramEmail, setParamEmail] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  // Configure Firebase.
  firebase.initializeApp(firebaseConfig);

  type FormData = {
    email: string;
  };

  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      email: ''
    }
  });

  const backToLogin = () => {
    history.push('/login');
  };

  const onFormSubmit = (formData: any) => {
    sendPasswordResetEmail(formData.email);
  };

  const sendPasswordResetEmail = async (email: string) => {
    gaBtnClickEvent('reset_password');
    dispatch(setLoading(true));
    await FirebaseAuthentication.sendPasswordResetEmail({ email: email })
      .then((result) => {
        setEmailError(null);
        setPasswordEmailSent(true);
        dispatch(setLoading(false));
      })
      .catch((error) => {
        console.log(`sendPasswordResetEmail error: ${error}`);
        setEmailError(error);
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (!!params.get('email')) {
      setParamEmail(params.get('email'));
    }
  }, []);

  function DesktopForgotPasswordResetView() {
    return (
      <IonPage>
        <IonContent className="hk-desktop-reset-view">
          <div className="hk-desktop-reset-view-container">
            <Illustration image={HomeKeepLogo} description="HomeKeep" />
            <div className="hk-desktop-reset-view-content-container">
              <div className="hk-desktop-reset-view-content">
                {!passwordEmailSent && !!paramEmail && (
                  <section className="hk-desktop-reset-view-header">
                    <h3 className="hk-desktop-email-reset-view-title ion-text-center">
                      <b>Reset your password</b>
                    </h3>
                    <h6 className="hk-desktop-reset-view-subheader ion-padding-horizontal ion-text-center">
                      In order to complete your setup please reset your
                      password.
                    </h6>
                    <IonButton
                      className="hk-desktop-email-reset-button"
                      color="primary"
                      size="default"
                      strong={true}
                      onClick={() => sendPasswordResetEmail(paramEmail)}
                    >
                      Reset Password
                    </IonButton>
                  </section>
                )}
                {!passwordEmailSent && !paramEmail && (
                  <section className="hk-desktop-reset-view-header">
                    <h3 className="hk-desktop-reset-view-title ion-text-center">
                      <b>Forgot your password?</b>
                    </h3>
                    <h6 className="hk-desktop-reset-view-subheader ion-padding-horizontal ion-text-center">
                      Enter the email associated with your account and we will
                      send an email to reset your password.
                    </h6>
                  </section>
                )}
                {passwordEmailSent && (
                  <section className="hk-desktop-reset-view-header">
                    <h3 className="hk-desktop-reset-view-title ion-text-center">
                      <b>Check your inbox!</b>
                    </h3>
                    <h6 className="hk-desktop-reset-view-subheader ion-padding-horizontal ion-text-center">
                      An email with instructions has been sent. Once your
                      password is reset you may{' '}
                      <div>
                        <IonRouterLink routerLink="login">
                          Sign In
                        </IonRouterLink>
                        .
                      </div>
                    </h6>
                  </section>
                )}
                {!passwordEmailSent && !paramEmail && (
                  <section className="hk-desktop-reset-view-reset-form ion-padding-vertical ion-margin-vertical">
                    <form
                      className="hk-form"
                      onSubmit={handleSubmit(onFormSubmit)}
                    >
                      <div className="hk-form-row">
                        <input
                          name="email"
                          ref={register}
                          className="login-field"
                          placeholder="Email"
                        />
                      </div>
                      <div className="hk-form-actions">
                        <IonButton
                          className="hk-auth-button ion-margin-vertical"
                          expand="block"
                          color="primary"
                          type="submit"
                          strong={true}
                          disabled={loading}
                        >
                          {loading ? (
                            <IonSpinner name="dots" />
                          ) : (
                            'Reset Password'
                          )}
                        </IonButton>
                      </div>
                      {emailError && (
                        <p className="hk-error">
                          Your email appears to be invalid.
                        </p>
                      )}
                    </form>
                  </section>
                )}
              </div>
            </div>
            {loading && (
              <div className="hk-body-spinner">
                <IonRow className="vertical-align-center">
                  <IonSpinner name="dots" />
                </IonRow>
              </div>
            )}
          </div>
        </IonContent>
      </IonPage>
    );
  }

  function MobileForgotPasswordResetView() {
    return (
      <IonPage>
        <IonContent
          className="reset-view ion-padding"
          fullscreen
          scrollY={true}
        >
          {!passwordEmailSent && !!paramEmail && (
            <section className="reset-view-header">
              <h3 className="ion-text-center">
                <b>Reset your password</b>
              </h3>
              <h6 className="reset-view-subheader ion-padding-horizontal ion-text-center">
                In order to complete your setup please reset your password.
              </h6>
              <IonButton
                className="ion-margin-vertical"
                color="primary"
                size="default"
                strong={true}
                onClick={() => sendPasswordResetEmail(paramEmail)}
              >
                Reset Password
              </IonButton>
            </section>
          )}
          {!passwordEmailSent && !paramEmail && (
            <section className="reset-view-header">
              <h3 className="ion-text-center">
                <b>Forgot your password?</b>
              </h3>
              <h6 className="reset-view-subheader ion-padding-horizontal ion-text-center">
                Enter the email associated with your account and we will send an
                email to reset your password.
              </h6>
            </section>
          )}
          {passwordEmailSent && (
            <section className="reset-view-header">
              <h3 className="ion-text-center">
                <b>Check your inbox!</b>
              </h3>
              <h6 className="reset-view-subheader ion-padding-horizontal ion-text-center">
                An email with instructions has been sent. Once your password is
                reset you may{' '}
                <IonRouterLink routerLink="login">Sign In</IonRouterLink>.
              </h6>
            </section>
          )}
          {!passwordEmailSent && !paramEmail && (
            <section className="reset-view-email-form ion-padding-vertical ion-margin-vertical">
              <form className="hk-form" onSubmit={handleSubmit(onFormSubmit)}>
                <div className="hk-form-row">
                  <input
                    name="email"
                    ref={register}
                    className="login-field"
                    placeholder="Email"
                  />
                </div>
                <div className="hk-form-actions">
                  <IonButton
                    className="hk-auth-button ion-margin-vertical"
                    expand="block"
                    color="primary"
                    type="submit"
                    strong={true}
                    disabled={loading}
                  >
                    {loading ? <IonSpinner name="dots" /> : 'Reset Password'}
                  </IonButton>
                </div>
                {emailError && (
                  <p className="hk-error">Your email appears to be invalid.</p>
                )}
              </form>
            </section>
          )}
          {loading && (
            <div className="hk-body-spinner">
              <IonRow className="vertical-align-center">
                <IonSpinner name="dots" />
              </IonRow>
            </div>
          )}
          <IonFab vertical="top" horizontal="start">
            <IonFabButton
              color="white"
              className="hk-fab-button ion-margin-top"
              onClick={backToLogin}
            >
              <IonIcon color="medium" icon={LeftArrow} />
            </IonFabButton>
          </IonFab>
        </IonContent>
      </IonPage>
    );
  }

  return accessToken ? (
    <Redirect to="/checkpoint" />
  ) : (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth ? (
        <DesktopForgotPasswordResetView />
      ) : (
        <MobileForgotPasswordResetView />
      )}
    </>
  );
};

export default ForgotPasswordReset;
